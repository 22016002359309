import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import("../views/home.vue"),
    props: true
  },
  {
    path: "/schedule/",
    name: "schedule",
    component: () =>
      import("../views/schedule.vue"),
    props: true
  },
  {
    path: "/q-school/",
    name: "q-school",
    component: () =>
      import("../views/single.vue"),
    props: true
  },
  {
    path: "/asian-tour-q-school-full/",
    name: "asian-tour-q-school-full",
    component: () =>
      import("../views/single.vue"),
    props: true
  },
  {
    path: "/tv-schedule/",
    name: "tv-schedule",
    component: () =>
      import("../views/single.vue"),
    props: true
  },
  {
    path: "/contact-us/",
    name: "contact-us",
    component: () =>
      import("../views/single.vue"),
    props: true
  },
  {
    path: "/introduction-and-history/",
    name: "introduction-and-history",
    component: () =>
      import("../views/single.vue"),
    props: true
  },
  {
    path: "/data-protection/",
    name: "data-protection",
    component: () =>
      import("../views/single.vue"),
    props: true
  },
  {
    path: "/objectives-and-activities/",
    name: "objectives-and-activities",
    component: () =>
      import("../views/single.vue"),
    props: true
  },
  {
    path: "/cookies/",
    name: "cookies",
    component: () =>
      import("../views/single.vue"),
    props: true
  },
  {
    path: "/terms-of-use/",
    name: "terms-of-use",
    component: () =>
      import("../views/single.vue"),
    props: true
  },
  {
    path: "/kyi-hla-han-future-champions/",
    name: "kyi-hla-han-future-champions",
    component: () =>
      import("../views/single.vue"),
    props: true
  },
  {
    path: "/the-international-series",
    name: "the-international-series",
    component: () =>
      import("../views/single.vue"),
    props: true
  },
  {
    path: "/reports",
    name: "reports",
    component: () =>
      import("../views/reports.vue"),
    props: true
  },
  {
    path: "/reports",
    name: "reports?tour=adt",
    component: () =>
      import("../views/reports.vue"),
    props: true
  },
  {
    path: "/leaderboard",
    name: "leaderboard",
    component: () =>
      import("../views/leaderboard.vue"),
    props: true
  },
  {
    path: "/players/",
    name: "players",
    component: () =>
      import("../views/players.vue"),
    props: true
  },
  {
    path: "/oom/:homepage",
    name: "oom",
    component: () => import("../views/oom.vue"),
    props: true
  },
  {
    path: "/oomseries/:homepage",
    name: "oomseries",
    component: () => import("../views/oomseries.vue"),
    props: true
  },
  {
    path: "/playerprofile/:refno/",
    name: "playerprofile",
    component: () =>
      import("../views/playerprofile.vue"),
    props: true
  },
  {
    path: "/news",
    name: "news",
    component: () => import("../views/news.vue")
  },

  {
    path: "/video",
    name: "video",
    component: () =>
      import("../views/video.vue")
  },
  {
    path: "/stats",
    name: "stats",
    component: () =>
      import("../views/stats.vue")
  },
  {
    path: "/qschool",
    name: "qschool",
    component: () =>
      import("../views/qschool.vue")
  },
  {
    path: "/tournament/:course_code/:id",
    name: "tournament",
    component: () =>
      import("../views/tournament.vue"),
    props: true
  },
  {
    path: "/links/",
    name: "links",
    component: () =>
      import("../views/links.vue"),
    props: true
  },
  {
    path: "/partners",
    name: "partners",
    component: () =>
      import("../views/partners.vue"),
    props: true
  },
  {
    path: "/individual-partners/:slug",
    name: "individual-partners",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/indiPartners.vue"),
    props: true
  },
  {
    path: "/destinations/",
    name: "destinations",
    component: () =>
      import("../views/destinations.vue"),
    props: true
  },
  {
    path: "/destinations-partners/",
    name: "destinations-partners",
    component: () =>
      import("../views/partners.vue"),
    props: true
  },
  {
    path: "/destinations/:id",
    name: "destinations",
    component: () =>
      import("../views/golfCourse.vue"),
    props: true
  },
  {
    path: "/asian-tour-destinations-giveaway-contest/",
    name: "asian-tour-destinations-giveaway-contest",
    component: () =>
      import("../views/single.vue"),
    props: true
  },
  {
    path: "/adt", 
    name: "adt",
    component: () =>
      import("../views/homeADT.vue"),
    props: true
  },
  {
    path: "/search",
    name: "search",
    component: () =>
      import("../views/search.vue"),
    props: true
  },
  {
    path: "/adt/:id",
    name: "adt-newStory",
    component: () =>
      import("../views/newsStoryADT.vue"),
    props: true
  },
  {
    path: "/:id",
    name: "newStory",
    component: () =>
      import("../views/newsStory.vue"),
    props: true
  },
  {
    path: "/:slug",
    name: "",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue"),
    props: true
  },
  { 
    path: '/:catchAll(.*)', 
    name: 'NotFound'
  }
];

const router = new VueRouter({
  routes,
  mode:'history',
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
});

export default router;
