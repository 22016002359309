import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueMeta from "vue-meta";
import VueSocialSharing from 'vue-social-sharing'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faWeibo } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { faVolumeUp } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import JQuery from "jquery";
import VueGtag from "vue-gtag";
import VueLodash from 'vue-lodash';
import lodash from 'lodash'
import Information from "@ocs-software/vue-ocs/ocs/reports/information.vue";

library.add(
  faArrowDown,
  faArrowUp,
  faFacebookSquare,
  faInstagram,
  faTwitter,
  faYoutube,
  faWeibo,
  faEnvelope,
  faUser,
  faGlobe,
  faPlayCircle,
  faArrowLeft,
  faCheck,
  faTimes,
  faSearch,
  faLinkedin,
  faPrint,
  faVolumeUp,
  faChevronLeft,
  faChevronRight,
);
Vue.use(VueSocialSharing);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("Information", Information);
Vue.use(VueLodash, { name: 'custom' , lodash: lodash })
Vue.config.productionTip = false;
Vue.use(VueMeta);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueGtag, {
  config: { id: "G-JX0BZ9C72M" },
  includes: [
    { id: 'G-WW113PW174' }
  ]
}, router);

new Vue({
  router,
  store,
  axios,
  JQuery,
  render: h => h(App)
}).$mount("#app");
