<template>
  <div class="footer" v-if="loading === true">
    <template v-if='isMobile(true)'>
      <b-row >
        <b-col cols="6" v-for="(pageLinks, index) in links" :key="index">
          <b-nav-item
            :href="pageLinks.acf.url"
            class="footLinks"
          >
            {{ pageLinks.acf.text }}
          </b-nav-item>
        </b-col>
      </b-row>
      <div class="centerContent">
        <b-row class="DownloadSection">
          <b-col>
            <h5 class="footerSize">
              Download our mobile app
            </h5>
            <div class="socialTabs">
              <div class="downloadMobileapps AlignPadLeft">
                <a
                  href="https://play.google.com/store/apps/details?id=com.asiantour&hl=en_GB&gl=US"
                  target="_blank"
                >
                  <b-img
                    class="mobAppstore"
                    src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/tartanprotour/2021/03/Mask-Group-4.png"
                  ></b-img>
                </a>
              </div>
              <div class="downloadMobileapps">
                <a
                  :href="config.VUE_APP_APPLE_STORE"
                  target="_blank"
                >
                  <b-img
                    class="mobAppstore"
                    src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/tartanprotour/2021/03/Mask-Group-5.png"
                  ></b-img>
                </a>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h5 class="footerSize">
              Connect with us
            </h5>
            <b-row class="sociallinks AlignPadLeft">
              <b-col class="col-1 socialMob-icons">
                <b-nav-item href="https://twitter.com/asiantourgolf">
                  <font-awesome-icon
                    class="icon mobMenuFooter"
                    :icon="['fab', 'twitter']"
                  />
                </b-nav-item>
              </b-col>
              <b-col class="col-1 socialMob-icons">
                <b-nav-item href="https://facebook.com/asiantourgolf">
                  <font-awesome-icon
                    class="icon mobMenuFooter"
                    :icon="['fab', 'facebook-square']"
                  />
                </b-nav-item>
              </b-col>
              <b-col class="col-1 socialMob-icons">
                <b-nav-item
                  href="https://www.youtube.com/@theasiantour"
                >
                  <font-awesome-icon
                    class="icon mobMenuFooter"
                    :icon="['fab', 'youtube']"
                  />
                </b-nav-item>
              </b-col>
              <b-col class="col-1 socialMob-icons mobMenuFooter">
                <b-nav-item href="https://weibo.com/asiantourgolf">
                  <font-awesome-icon class="icon" :icon="['fab', 'weibo']" />
                </b-nav-item>
              </b-col>
              <b-col class="col-1 socialMob-icons">
                <b-nav-item href="https://instagram.com/asiantour">
                  <font-awesome-icon
                    class="icon mobMenuFooter"
                    :icon="['fab', 'instagram']"
                  />
                </b-nav-item>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <p class="MobATtext">
              The Asian Tour logo is a trademark of Asian Tour Limited. Copyright in
              the logo and other content on this website is owned by Asian Tour
              Limited or used under license from third parties. All rights reserved.
            </p>
          </b-col>
        </b-row>
      </div>
    </template>
    <template v-else>
      <b-container>
        <b-row class="nomargin">
          <b-col lg='6' md='12'>
            <b-row class="nomargin">
              <b-col
                lg="6"
                v-for="(pageLinks, index) in links.slice(0, 2)"
                :key="index"
              >
                <b-nav-item
                  :href="pageLinks.acf.url"
                  class="footLinks"
                  >{{ pageLinks.acf.text }}</b-nav-item
                >
              </b-col>
            </b-row>
            <b-row class="nomargin">
              <b-col
                lg="6"
                v-for="(pageLinks, index) in links.slice(2, 4)"
                :key="index"
              >
                <b-nav-item
                  :href="pageLinks.acf.url"
                  class="footLinks"
                  >{{ pageLinks.acf.text }}</b-nav-item
                >
              </b-col>
            </b-row>
            <b-row class="nomargin">
              <b-col
                lg='6'
                v-for="(pageLinks, index) in links.slice(4, 6)"
                :key="index"
              >
                <b-nav-item
                  :href="pageLinks.acf.url"
                  class="footLinks"
                  >{{ pageLinks.acf.text }}</b-nav-item
                >
              </b-col>
            </b-row>
          </b-col>
          <b-col lg='6' md='12' class='rP'>
            <b-row class="nomargin rightNoMargin">
                  <b-col>
                    <b-row class="AlignPadLeft">
                      <b-col class="AlignPadLeft">
                        <h5 class="footerSize">
                          Download our mobile app
                        </h5>
                      </b-col>
                    </b-row>
                    <div class="socialTabs">
                      <div class="downloadMobileapps AlignPadLeft">
                        <a
                          href="https://play.google.com/store/apps/details?id=com.asiantour&hl=en_GB&gl=US"
                          target="_blank"
                        >
                          <b-img
                            class="appStore"
                            src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/tartanprotour/2021/03/Mask-Group-4.png"
                          ></b-img>
                        </a>
                      </div>
                      <div class="downloadMobileapps">
                        <a
                          :href="config.VUE_APP_APPLE_STORE"
                          target="_blank"
                        >
                          <b-img
                            class="appStore"
                            src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/tartanprotour/2021/03/Mask-Group-5.png"
                          ></b-img>
                        </a>
                      </div>
                    </div>
                  </b-col>
              <b-col lg='6' md='6' cols='12'>
                <b-row class="socialRow nomargin AlignPadLeft">
                  <b-row>
                    <b-col class="AlignPadLeft">
                      <h5 class="footerSize connectSocial">
                        Connect with us
                      </h5>
                    </b-col>
                  </b-row>
                  <b-row class="sociallinks AlignPadLeft">
                    <b-col class="col-md-2 col-4 socialMob-icons lp">
                      <b-nav-item href="https://twitter.com/asiantourgolf">
                        <font-awesome-icon
                          class="icon"
                          :icon="['fab', 'twitter']"
                        />
                      </b-nav-item>
                    </b-col>
                    <b-col class="col-md-2 col-4 socialMob-icons">
                      <b-nav-item href="https://facebook.com/asiantourgolf">
                        <font-awesome-icon
                          class="icon"
                          :icon="['fab', 'facebook-square']"
                        />
                      </b-nav-item>
                    </b-col>
                    <b-col class="col-md-2 col-4 socialMob-icons">
                      <b-nav-item
                         href="https://www.youtube.com/@theasiantour"
                      >
                        <font-awesome-icon
                          class="icon"
                          :icon="['fab', 'youtube']"
                        />
                      </b-nav-item>
                    </b-col>
                    <b-col class="col-md-2 col-4 socialMob-icons">
                      <b-nav-item href="https://weibo.com/asiantourgolf">
                        <font-awesome-icon class="icon" :icon="['fab', 'weibo']" />
                      </b-nav-item>
                    </b-col>
                    <b-col class="col-md-2 col-4 socialMob-icons">
                      <b-nav-item href="https://instagram.com/asiantour">
                        <font-awesome-icon
                          class="icon"
                          :icon="['fab', 'instagram']"
                        />
                      </b-nav-item>
                    </b-col>
                  </b-row>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="nomargin">
          <p id="tMark">
            The Asian Tour logo is a trademark of Asian Tour Limited. Copyright in
            the logo and other content on this website is owned by Asian Tour
            Limited or used under license from third parties. All rights reserved.
          </p>
        </b-row>
      </b-container>
    </template>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Footer",
  props: ["develop",'config'],
  data() {
    return {
      footerData: [],
      links: [],
      name: "",
      nameState: null,
      submittedNames: [],
      email: "",
      emailState: null,
      submittedEmail: [],
      loading: ''
    };
  },
  methods: {
    isMobile() {
      if( screen.width <= 990 ) {
        return true;
      }
      else {
        return false;
      }
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      this.emailState = valid;
      return valid;
    },
    resetModal() {
      this.name = "";
      this.nameState = null;
      this.email = "";
      this.emailState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name);
      this.submittedEmail.push(this.email);
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },
  },
  mounted() {
    axios //add API Call
      .get(process.env.VUE_APP_WP_ASIAN_TOUR_API_URL + "wp/v2/linkspon?_embed") // Page number changed on each page based on JSON page id you are calling
      .then((response) => {
        this.links = response.data;
        return axios.get(
          process.env.VUE_APP_WP_ASIAN_TOUR_API_URL + "wp/v2/Yearbook-footer?_embed"
        );
      })
      .then((response) => {
        this.footerData = response.data;
        this.loading = true
      });
  },
};
</script>

<style scoped>
@-moz-document url-prefix() {
  .connectSocial
  {
    padding-left: 7%;
    padding-bottom: 30px!important;
  }
}
.centerContent {
  text-align: center;
  margin: auto;
}
p.MobATtext {
  font-size: 9px;
  width: 75%;
}
.mobAppstore {
  width: 130px;
  margin-top: 5px;
}
.DownloadSection {
  margin-top: 30px;
  margin-bottom: 30px;
}
.socialTabs {
  display: flex;
}
.col-md-2.col-4.socialMob-icons.col {
  position: relative;
  top: -25px;
  left: 9px;
}
.rightNoMargin {
  /* margin-top: 18px; */
  margin-top: 5px;
}
.spLogo {
  width: 150px;
  margin-top: 20px;
  mix-blend-mode: multiply;
}
a {
  color: rgb(255, 255, 255) !important;
}
.col-6.downloadMobileapps.col {
  padding-left: 0;
}
.sociallinks {
  /* margin-top: -20px; */
  margin-top: 0px;
  padding-bottom: 30px;
  text-align: center;
}
.connectSocial {
  padding-left: 7%;
}
.footerSize {
  font-size: 14px;
}
.footerYearbook {
  font-size: 14px;
  padding-bottom: 8px;
}
.footer {
  background-color: #0A3F7F;
  color: white;
  padding-top: 3%;
  padding-left: 2%;
  padding-bottom: 3%;
}
.developGreen {
  background-color: #76c3bd !important;
}
.reviewImg {
  /* width: 78%; */
  width: 100%;
}
.nav-link {
  display: contents;
  color: white;
}
svg {
  font-size: 18px;
}
.iconE {
  font-size: 1rem;
}
.appStore {
  /* width: 115px; */
  width: 90px;
}
#tMark {
  font-size: 0.6rem;
  text-align: left;
  width: 100%;
  padding-top: 0%;
  padding-left: 3.5em;
  position: relative;
  top: 5em;
}
.footLinks {
  /* padding: 2%; */
  /* border-bottom: 1px solid #16498f; */
  padding-bottom: 5px;
  padding-top: 5px;
  font-size: 14px;
}
.developGreenLinks {
  border-bottom: 1px solid white !important;
}
.socialRow {
  width: 100%;
  display: block;
}
#sub {
  padding-top: 15px;
  padding-bottom: 10px;
}
.lp{
  padding-left: 9%;
}
@media only screen and (max-width: 1440px) {
  ::v-deep .nav-link {
    font-size: 0.9rem;
  }
}
@media only screen and (max-width: 1024px) {
  h5 {
    font-size: 1rem;
  }
  ::v-deep .nav-link {
    font-size: 0.7rem;
  }
  svg {
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 768px) {
  .nomargin {
    /* margin-right: 0 !important;
    margin-left: 0 !important; */
  }
  .rP{
    padding-top: 30px;
  }
  .spLogo {
    width: 100px !important;
    mix-blend-mode: multiply;
  }
  .connectSocial {
    padding-left: 4%;
  }
  .mobHide {
    display: none;
  }
  .col-12 {
    padding-bottom: 5%;
  }
  .reviewImg {
    width: 100% !important;
    padding-right: 10px;
  }
  .socialRow {
    display: block;
  }
  .socialMob-icons {
    max-width: 11.333333%;
  }
}
@media only screen and (max-width: 500px) {
  .socialTabs {
    display: inline-flex;
    margin: auto;
    text-align: center;
  }
  p.MobATtext {
    font-size: 9px;
    width: 75%;
    margin: auto;
  }
  .sociallinks {
    margin-top: 0px;
    padding-bottom: 30px;
    text-align: center;
    margin-left: 100px;
  }
  .socialRow {
    padding-left: 5%;
  }
  .col-md-2.col-4.socialMob-icons.lp.col {
    /* padding-left: 15px; */
    margin-right: 15px;
  }
  .row.socialRow.nomargin.AlignPadLeft {
    padding-left: 0px;
  }
  .AlignPadLeft {
    /* padding-left: 0px; */
  }
  #tMark {
    font-size: .6rem;
    text-align: center;
    width: 100%;
    padding-top: 5%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .footLinks {
    padding-bottom: 5px;
    padding-top: 5px;
    font-size: 14px;
    display: block;
    padding-left: 45px;
  }
}
</style>
