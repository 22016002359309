<!-- Nav Menu -->

<template>
  <div id="app">
    <Menu
      @season="setSeason"
      @seasonsadt="Setseasonsadt"
      @custom-event-name="setMessage"
      @currentReport="setReport"
      @currentTitle="setTitle"
      :config="env"
      :develop="develop"
    />
    <transition name="fade" appear>
      <router-view
        :key="$route.fullPath"
        :develop="develop"
        :currentReport="currentReport"
        :title="currentTitle"
        :season="season"
        :seasonsadt="seasonsadt"
        :oom="oom"
        :config="env"
    /></transition>
    <Footer
     :develop="develop" 
     :config="env" 
     :title="currentTitle"
     :currentReport="currentReport"
     :season="season"
     />
  </div>
</template>
<script>
  <meta name="apple-itunes-app" content="app-id=1380083557"></meta>
</script>
<style>
a {
  color: #337ab7 !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-active {
  transition-delay: 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
#app {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14pt;
}
li.nav-item::marker {
  color: transparent;
}
h3 {
  font-size: 24px !important;
  font-weight: 700;
}

@media only screen and (max-width: 480px) {
  #app {
    font-size: 10pt;
  }
}
</style>

<script>
import Footer from "@/components/footer.vue";
import Menu from "@/components/menu.vue";

export default {
  components: {
    Footer,
    Menu,
  },
  data() {
    return {
      develop: "",
      season: "",
      seasonsadt:'',
      oom: "",
      env: [],
      currentReport: "",
      currentTitle: "",
    };
  },
  methods: {
    // Define method that will use the payload to update the data property
    setReport(payload) {
      this.currentReport = payload.currentReport;
    },
    setTitle(payload) {
      this.currentTitle = payload.currentTitle;
    },
    setMessage(payload) {
      this.develop = payload.develop;
      this.oom = payload.oom;
    },
    setSeason(payload) {
      this.season = payload.season;
    },
    Setseasonsadt(payload) {
      this.seasonsadt = payload.seasonsadt;
    },
    setDevelop() {
      if (this.$route.name === "adt" || this.$route.name === 'adt-newStory' || this.develop === "adt" || this.$route.fullPath.includes('adt')) {
        (this.develop = process.env.VUE_APP_ID_ADT),
          (this.oom = process.env.VUE_APP_ADT_OOM);
      } else {
        (this.develop = process.env.VUE_APP_ID_ASIAN_TOUR),
        (this.oom = process.env.VUE_APP_ASIAN_OOM);
      }
    },
  },
  watch: {
    $route() {
      this.$nextTick(this.setDevelop);
    },
  },
  mounted() {
    this.env = process.env;
    this.$route.onReady(() => this.setDevelop());
  },
};
</script>
