<template>
  <div>
    <template v-if='isMobile(true)'>
      <div class="menu menuMobile">
        <b-row>
          <b-col>
            <div>
              <b-link to='/'>
                <img class="White_logo" src="https://assets.asiantour.com/asian-tour/2023/11/Asian-Tour-Logo_WHITE.png">
              </b-link>
            </div>
          </b-col>
          <b-col>
            <span class="openNav" @click="openNav()">&#9776;</span>
          </b-col>
        </b-row>
      </div>

      <div id="myNav" class="overlay">
        <a class="closebtn" @click="closeNav()">&times;</a>
        <div class="overlay-content">
          <template v-if="reports.report_title  === 'Tournament Entries'">
            <b-nav-item
              @click="hideNav()"
              :to="{
                name: 'reports',
                query: {
                  url:
                    'https://asian.ocs-asia.com/tic/tmentry.cgi?tourn=' +
                    code +
                    '~season=' +
                    seasons +
                    '~alphaorder~',
                  id: seasons,
                  code: code,
                  title: 'Tournament Entries',
                },
              }"
              >Leaderboard</b-nav-item
            >
          </template>
          <template v-else-if="reports.length > 1">
            <b-nav-item
              @click="hideNav()"
                :to="{
                  name: 'reports',
                  query: {
                    url: this.currentReport,
                    id: seasons,
                    code: code,
                    title: this.currentTitle,
                  },
                }"
            >
              Leaderboard
            </b-nav-item>
          </template>
          <b-nav-item :to="'/schedule'" @click="hideNav()">Schedule</b-nav-item>
        </div>
        <div class="overlay-contentDrop">
          <b-nav-item v-b-toggle="'collapse-2'" class="rankingsMenu mainItem">
            Media 
            <span class="imageArrowMedia">
            </span>
          </b-nav-item>
          <b-collapse id="collapse-2">
            <ul>
              <li class="liRanksLinks">
                <b-nav-item class="rankLinks" @click="hideNav()" :to="{
                  name: 'news',
                }">News</b-nav-item>
              </li>
              <li class="liRanksLinks">
                <b-nav-item class="rankLinks"  @click="hideNav()" :to="{
                  name: 'video',
                }">Videos</b-nav-item>
              </li>
              <li class="liRanksLinks">
                <b-nav-item class="rankLinks"  @click="hideNav()" :to="{
                  name: 'tv-schedule',
                }">TV Schedule</b-nav-item>
              </li>
            </ul>
          </b-collapse>
            <!-- <b-nav-item :to="'/news/'" @click="hideNav()">News</b-nav-item> -->
            <!-- <b-nav-item :to="'/q-school'" @click="hideNav()">Q-School</b-nav-item> -->
            <!-- <b-nav-item href="https://asiantour.com/adt" target="_blank" class="topNavLink" @click="hideNav()">Asian Development Tour</b-nav-item> -->
          <b-nav-item v-b-toggle="'collapse-3'" class="rankingsMenu mainItem playersLink">
            Players 
            <span class="imageArrowPlayers">
            </span>
          </b-nav-item>
          <b-collapse id="collapse-3">
            <ul>
              <li class="liRanksLinks">
                <b-nav-item class="rankLinks" @click="hideNav()" :to="{
                  name: 'players',
                }">Profiles</b-nav-item>
              </li>
              <li class="liRanksLinks">
                <b-nav-item class="rankLinks" @click="hideNav()" :to="{
                  name: 'stats',
                  query: {
                    id: seasons,
                  },
                }">Statistics</b-nav-item>
              </li>
              <li class="liRanksLinks">
                <b-nav-item class="rankLinks" @click="hideNav()" :to="{
                  name: 'q-school',
                }">Qualifying School</b-nav-item>
              </li>
              <li class="liRanksLinks">
                <b-nav-item class="rankLinks" @click="hideNav()" :to="{
                  name: 'kyi-hla-han-future-champions',
                }">Kyi Hla Han Future Champions Programme</b-nav-item>
              </li>
            </ul>
          </b-collapse>
        </div>
        <div class="overlay-content">
          <!-- <b-nav-item :to="'/players'" @click="hideNav()">Players</b-nav-item> -->
          <!-- <b-nav-item 
            :to="{
              name: 'stats',
              query: {
                id: seasons,
              },
            }" @click="hideNav()"
          >Statistics</b-nav-item> -->
          <!-- <b-nav-item :to="{name: 'video'}" @click="hideNav()">Videos</b-nav-item> -->
          <!-- <b-nav-item :to="{name: 'the-international-series'}" @click="hideNav()">The International Series</b-nav-item> -->
          <b-nav-item :to="{name: 'partners'}" @click="hideNav()">Partners</b-nav-item>
          <b-nav-item :to="{name: 'destinations'}" @click="hideNav()">Destinations</b-nav-item>
          <!-- <b-nav-item :to="'/tv-schedule'" @click="hideNav()">TV Schedule</b-nav-item> -->
          <!-- <template v-if="allow_qschool != 'N' || allow_qschool != 'A'">
            <b-nav-item :to="'/q-school'" @click="hideNav()">Q-School</b-nav-item>
          </template> -->
          <!-- <b-nav-item :to="'/kyi-hla-han-future-champions'" @click="hideNav()">Kyi Hla Han Future Champions Programme</b-nav-item> -->
          <hr class="hrline">  
          <b-nav-item :to="{
              name: 'stats',
              query: {
                id: seasons,
                oom:'Y'
              },
            }" class="topNavLink" @click="hideNav()">Order of Merit</b-nav-item>
          <b-nav-item 
              :to="{
                name: '/oomseries/rankings',
              }" 
              class="topNavLink" @click="hideNav()">International Series Rankings
          </b-nav-item>
          <b-nav-item href="https://asiantour.com/adt" target="_blank" class="topNavLink" @click="hideNav()">Asian Development Tour</b-nav-item>
          <hr class="hrline">
          <b-row class="sociallinks AlignPadLeft">
            <b-col class="col-1 socialMob-icons lp">
              <b-nav-item href="https://twitter.com/asiantourgolf">
                <font-awesome-icon
                  class="icon mobMenuFooter"
                  :icon="['fab', 'twitter']"
                />
              </b-nav-item>
            </b-col>
            <b-col class="col-1 socialMob-icons">
              <b-nav-item href="https://facebook.com/asiantourgolf">
                <font-awesome-icon
                  class="icon mobMenuFooter"
                  :icon="['fab', 'facebook-square']"
                />
              </b-nav-item>
            </b-col>
            <b-col class="col-1 socialMob-icons">
              <b-nav-item
                :href="'https://www.youtube.com/channel/'+ config.VUE_APP_YOUTUBE_ID"
              >
                <font-awesome-icon
                  class="icon mobMenuFooter"
                  :icon="['fab', 'youtube']"
                />
              </b-nav-item>
            </b-col>
            <b-col class="col-1 socialMob-icons mobMenuFooter">
              <b-nav-item href="https://weibo.com/asiantourgolf">
                <font-awesome-icon class="icon" :icon="['fab', 'weibo']" />
              </b-nav-item>
            </b-col>
            <b-col class="col-1 socialMob-icons">
              <b-nav-item href="https://instagram.com/asiantour">
                <font-awesome-icon
                  class="icon mobMenuFooter"
                  :icon="['fab', 'instagram']"
                />
              </b-nav-item>
            </b-col>
          </b-row>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="menu" v-if="loading === true">
        <div id="nav">
          <b-container>
            <b-navbar toggleable="lg" type="dark" variant="info">
              <b-navbar-brand href="/">
                <img class="White_logo" src="https://assets.asiantour.com/asian-tour/2023/11/Asian-Tour-Logo_WHITE.png">
              </b-navbar-brand>

                <!-- Right aligned nav items -->
                <b-navbar-nav class="ml-auto hideMob">
                  <b-navbar-nav>
                    <b-nav-item :to="{
                      name: 'stats',
                      query: {
                        id: seasons,
                        oom:'Y'
                      },
                    }"
                   class="topNavLink">Asian Tour Order Of Merit</b-nav-item>
                    <b-nav-item class="pipTopNav">
                      <div class="inlinePip"></div>
                    </b-nav-item>
                    <b-nav-item :to="'/oomseries/rankings'"
                      class="topNavLink">International Series Rankings</b-nav-item>
                    <b-nav-item class="pipTopNav">
                      <div class="inlinePip"></div>
                    </b-nav-item>
                    <b-nav-item href="https://asiantour.com/adt" target="_blank" class="topNavLink">Asian Development Tour</b-nav-item>
                  </b-navbar-nav>
                </b-navbar-nav>
            </b-navbar>
          </b-container>
        </div>
        <div id="nav" class="secondNav">
            <b-container>
              <!-- New Menu -->
              <b-navbar type="light" toggleable="lg" id="topNav" align="center">
                <template>
                  <b-navbar-nav>
                    <b-navbar-toggle
                      class="ml-auto"
                      type="light"
                      target="nav-collapse"
                    ></b-navbar-toggle>
                    <b-collapse
                      id="nav-collapse"
                      is-nav
                    >
                      <template v-if="reports.report_title === 'Tournament Entries'">
                        <b-nav-item
                          :to="{
                            name: 'reports',
                            query: {
                              url:
                                'https://asian.ocs-asia.com/tic/tmentry.cgi?tourn=' +
                                code +
                                '~season=' +
                                seasons +
                                '~alphaorder~',
                              id: seasons,
                              code: code,
                              title: 'Tournament Entries',
                            },
                          }"
                          >Leaderboard</b-nav-item
                        >
                      </template>
                      <template v-else-if="reports.length > 1">
                        <b-nav-item  
                            :to="{
                              name: 'reports',
                              query: {
                                url: this.currentReport,
                                id: seasons,
                                code: code,
                                title: this.currentTitle,
                              },
                            }"
                        >
                          Leaderboard
                        </b-nav-item>
                      </template>
                      <template v-if="reports.report_title === 'Tournament Entries' || reports.length > 1">
                        <b-nav-item class="pipTopNav">
                          <div class="inlinePip menuPip"></div>
                        </b-nav-item>
                      </template>
                      <b-nav-item :to="'/schedule'">Schedule</b-nav-item>
                      <b-nav-item class="pipTopNav">
                        <div class="inlinePip menuPip"></div>
                      </b-nav-item>
                      <template>
                        <b-nav-item-dropdown
                          id="my-nav-dropdown"
                          text="Media"
                          toggle-class="nav-link-custom"
                          class="MoreSectionTwo"
                        >
                          <b-dropdown-item to="/news"
                            >News</b-dropdown-item
                          >
                          <b-dropdown-divider></b-dropdown-divider>
                          <b-dropdown-item to="/video"
                            >Videos</b-dropdown-item
                          >
                          <b-dropdown-divider></b-dropdown-divider>
                          <b-dropdown-item to="/tv-schedule"
                            >TV Schedule</b-dropdown-item
                          >
                        </b-nav-item-dropdown>
                      </template>
                      <b-nav-item class="pipTopNav">
                        <div class="inlinePip menuPip"></div>
                      </b-nav-item>
                      <template>
                        <b-nav-item-dropdown
                          id="my-nav-dropdown"
                          text="Players"
                          toggle-class="nav-link-custom"
                          class="MoreSection"
                        >
                          <b-dropdown-item to="/players"
                            >Profiles</b-dropdown-item
                          >
                          <b-dropdown-divider></b-dropdown-divider>
                          <b-dropdown-item :to="{
                            name: 'stats',
                            query: {
                              id: seasons,
                            },
                          }" 
                            >Statistics</b-dropdown-item
                          >
                          <template v-if="allow_qschool != 'N' || allow_qschool != 'A'">
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-item to="/q-school"
                              >Qualifying School</b-dropdown-item
                            >
                          </template>
                          <b-dropdown-divider></b-dropdown-divider>
                          <b-dropdown-item to="/kyi-hla-han-future-champions"
                            >Kyi Hla Han Future Champions Programme</b-dropdown-item
                          >
                        </b-nav-item-dropdown>
                      </template>      
                      <!-- <b-nav-item class="pipTopNav">
                        <div class="inlinePip menuPip"></div>
                      </b-nav-item> -->
                      <!-- <b-nav-item :to="'/news/'">News</b-nav-item> -->
                      <!-- <b-nav-item class="pipTopNav">
                        <div class="inlinePip menuPip"></div>
                      </b-nav-item> -->
                      <!-- <b-nav-item
                        :to="'/players'"
                      >
                        Players
                      </b-nav-item> -->
                      <!-- <b-nav-item class="pipTopNav">
                        <div class="inlinePip menuPip"></div>
                      </b-nav-item> -->
                      <!-- <b-nav-item 
                        :to="{
                          name: 'stats',
                          query: {
                            id: seasons,
                          },
                        }"  
                      >
                        Statistics
                      </b-nav-item> -->
                      <!-- <b-nav-item class="pipTopNav">
                        <div class="inlinePip menuPip"></div>
                      </b-nav-item> -->
                      <!-- <b-nav-item :to="{name: 'video'}">
                        Videos
                      </b-nav-item> -->
                      <b-nav-item class="pipTopNav">
                        <div class="inlinePip menuPip"></div>
                      </b-nav-item>
                      <!-- <b-nav-item :to="{name: 'the-international-series'}">
                        The International Series
                      </b-nav-item> -->
                      <!-- <b-nav-item class="pipTopNav">
                        <div class="inlinePip menuPip"></div>
                      </b-nav-item> -->
                      <b-nav-item :to="{name: 'partners'}">
                        Partners
                      </b-nav-item>
                      <b-nav-item class="pipTopNav">
                        <div class="inlinePip menuPip"></div>
                      </b-nav-item>
                      <b-nav-item :to="{name: 'destinations'}">
                        Destinations
                      </b-nav-item>
                      <!-- <b-nav-item class="pipTopNav">
                        <div class="inlinePip menuPip"></div>
                      </b-nav-item> -->
                      <template v-if='isMobile(true)'>
                        <b-button v-b-toggle.collapse-4 class="m-1 OOMBTN">More</b-button>
                        <b-collapse id="collapse-4">
                          <div class="OOMBlockMob">
                            <b-link :to="'/tv-schedule'">TV Schedule</b-link>
                            <template v-if="allow_qschool != 'N' || allow_qschool != 'A'">
                              <b-link :to="'/q-school'">Q-School</b-link>
                            </template>
                            <b-link :to="'/kyi-hla-han-future-champions'">Kyi Hla Han Future Champions Programme</b-link>
                          </div>
                        </b-collapse>
                      </template>
                      <!-- <template v-else>
                        <b-nav-item-dropdown
                          id="my-nav-dropdown"
                          text="More"
                          toggle-class="nav-link-custom"
                          class="MoreSection"
                        >
                          <b-dropdown-item to="/tv-schedule"
                            >TV Schedule</b-dropdown-item
                          >
                          <template v-if="allow_qschool != 'N' || allow_qschool != 'A'">
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-item to="/q-school"
                              >Q-School</b-dropdown-item
                            >
                          </template>
                          <b-dropdown-divider></b-dropdown-divider>
                          <b-dropdown-item to="/kyi-hla-han-future-champions"
                            >Kyi Hla Han Future Champions Programme</b-dropdown-item
                          >
                        </b-nav-item-dropdown>
                      </template>  -->
                    </b-collapse>
                  </b-navbar-nav>
                </template>
              </b-navbar>
            </b-container>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import axios from "axios";
// import tournament from '../views/tournament.vue';
export default {
  // components: { tournament },
  name: "Menu",
  props:['config', 'develop', 'qsc'],
  data() {
    return {
      menueLink: "menuLink",
      seasons: [],
      seasonsadt: [],
      code: [],
      class_group: [],
      reports: [],
      devCode: [],
      devReports: [],
      roundsPlayed:[],
      isHidden: false,
      loading: true,
      allow_qschool: ''
    };
  },
  computed: {
    filterReports: function() {
      if (Array.isArray(this.reports)) {
        return this.reports.filter(
          (reports_entry) =>
            reports_entry.report_title.includes("Tournament Entries") ||
            reports_entry.report_title.includes("Score") ||
            // reports_entry.report_title.includes("Live") ||
            reports_entry.report_title.includes("Final")
        );
      } else return this.reports; 
    },
    currentReport: function() {
      if (Array.isArray(this.filterReports))
        return this.filterReports[this.filterReports.length - 1].report_url;
      else return this.filterReports.report_url;
    },
    currentReportNav: function () {
      return this.reports.filter(
        value => value.report_url.includes('tmscores') || 
        value.report_url.includes('tmresult') ||
        value.report_url.includes('tmentry')
      )
    },
    currentTitle: function() {
      if (Array.isArray(this.filterReports))
        return this.filterReports[this.filterReports.length - 1].report_title;
      else return this.filterReports.report_title;
    },
  },
  methods: {
    openNav: function() {
      document.getElementById("myNav").style.width = "100%";
    },
    closeNav: function() {
      document.getElementById("myNav").style.width = "0%";
    },
    collapse() {
      console.log('IN')
      var coll = document.getElementsByClassName("collapsible");
      console.log("coll")
      console.log(coll)
      var i;

      for (i = 0; i < coll.length; i++) {
        console.log("line 291")
        coll[i].addEventListener("click", function() {
          console.log('line 293')
          this.classList.toggle("active");
          var content = this.nextElementSibling;
          console.log(newcoll)
          if (content.style.maxHeight){
            console.log('line 297')
            content.style.maxHeight = null;
            var newcoll = document.getElementsByClassName("menucol");
          } else {
            console.log('line 300')
            content.style.maxHeight = content.scrollHeight + "px";
            var newinnercoll = document.getElementsByClassName("innerMenuCol");
            console.log(newinnercoll)
          } 
        });
      }
    },
    hideNav: function() {
      this.closeNav()
    },
    isMobile() {
      if( screen.width <= 990 ) {
        return true;
      }
      else {
        return false;
      }
    },
    toggleClass: function() {
      this.isActive = !this.isActive;
    },
    emitAdtEvent() {
      this.develop = this.config.VUE_APP_ID_ADT;
      this.$emit("custom-event-name", { develop: this.config.VUE_APP_ID_ADT, oom: this.config.VUE_APP_ADT_OOM });
      // setTimeout(
      //   () => { 
      //     console.log("RELOAD"); 
      //     window.location.reload();
      //   }, 2000
      // );
    },
    emitApgaEvent() {
      this.develop = this.config.VUE_APP_ID_ASIAN_TOUR;
      // this.qsc = 'qsc'
      this.$emit("custom-event-name", { develop: this.config.VUE_APP_ID_ASIAN_TOUR, oom: this.config.VUE_APP_ASIAN_OOM });
    },
  },
  mounted() {
    axios
      .get(
        process.env.VUE_APP_TIC_BASE + "apga/tmticx?randomadd=" +
          new Date().getTime()
      )
      .then((response) => {
        this.allow_qschool = response.data.tm_params.allow_qschool;
        this.seasons = response.data.tm_params.season_code;
        this.code = response.data.code;
        this.class_group = response.data.class_group;
        this.reports = response.data.reports.reports_entry;
        this.roundsPlayed = response.data;
        this.$emit("currentReport", { currentReport: this.currentReport });
        this.$emit("currentTitle", { currentTitle: this.currentTitle });
        // console.log("this.roundsPlayed")
        // console.log(this.roundsPlayed)
        this.rounds = response.data.rounds_played;
        // console.log(this.rounds)
        this.$emit("season", { season: this.seasons });
        this.loading = true
        if (this.class_group === 'QSC') {
          return axios.get(
            process.env.VUE_APP_TIC_BASE + "qsc/tmticx?randomadd=" +
              new Date().getTime()
          ); 
        } else {
          return axios.get(
            process.env.VUE_APP_TIC_BASE + "adt/tmticx?randomadd=" +
              new Date().getTime()
          );
        }
      })
      .then((response) => {

        if ( this.class_group === 'QSC' ) {
          this.seasons = response.data.tm_params.season_code;
          this.code = response.data.code;
          this.reports = response.data.reports.reports_entry;
          this.roundsPlayed = response.data;
        } else {
          this.seasonsadt = response.data.tm_params.season_code;
          this.$emit("seasonsadt", { seasonsadt: this.seasonsadt });
          console.log("this.seasonsadt")
          console.log(this.seasonsadt)
          this.devCode = response.data.code;
          this.devReports = response.data.reports.reports_entry;
          this.rounds = response.data.round_played;
          this.loading = true
        }
      });
  },
};
</script>

<style scoped>
::v-deep ul.navbar-nav.ml-auto.hideMob > ul > li.nav-item.pipTopNav {
  padding-right: 10px!important;
}
span.imageArrowMedia {
  background-image: url(https://assets.asiantour.com/asian-tour/2024/04/White_NavArrow.png);
  background-repeat: no-repeat;
  background-size: contain;
  height: 10px;
  width: 12px;
  display: inline-flex;
  background-position: right;
  float: right;
}
span.imageArrowPlayers {
  background-image: url(https://assets.asiantour.com/asian-tour/2024/04/White_NavArrow.png);
  background-repeat: no-repeat;
  background-size: contain;
  height: 10px;
  width: 12px;
  display: inline-flex;
  background-position: right;
  float: right;
}
span.imageArrowMedia:hover {
  background-image: url(https://assets.asiantour.com/asian-tour/2024/04/Blue_NavArrow.png);
  background-repeat: no-repeat;
  background-size: contain;
  height: 10px;
  width: 12px;
  display: inline-flex;
  background-position: right;
  float: right;
}
span.imageArrowPlayers:hover {
  background-image: url(https://assets.asiantour.com/asian-tour/2024/04/Blue_NavArrow.png);
  background-repeat: no-repeat;
  background-size: contain;
  height: 10px;
  width: 12px;
  display: inline-flex;
  background-position: right;
  float: right;
}
::v-deep li.nav-item.rankingsMenu.mainItem.not-collapsed > a{
  /* color: #5897E6 !important; */
}
li.nav-item.rankingsMenu.mainItem.collapsed {
  padding-left: 32px;
  padding-right: 32px;
}
li.nav-item.rankingsMenu.mainItem.not-collapsed {
  padding-left: 32px;
  padding-right: 32px;
  background-color: #5897E6;
  padding-bottom: 10px;
  line-height: 0.8 !important;
  margin-top: 8px;
}

div#collapse-2 {
  background: rgba(10, 63, 127);
}
div#collapse-3 {
  background: rgba(10, 63, 127);
}
div#collapse-2 > ul {
  list-style-type: none;
  padding-left: 35px;
  padding-bottom: 15px;
}
div#collapse-3 > ul {
  list-style-type: none;
  padding-left: 35px;
  padding-bottom: 15px;
}
li.nav-item.rankLinks > a {
  color: #fff!important;
  font-size: 16px;
}
li.liRanksLinks {
  padding-top: 10px;
  padding-bottom: 10px;
}
::v-deep .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0em solid;
  border-right: 0em solid transparent;
  border-bottom: 0;
  border-left: 0em solid transparent;
  background-image: url(https://assets.asiantour.com/asian-tour/2024/04/White_NavArrow.png);
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: top;
  height: 16px;
  position: relative;
  left: 12px;
  top: 12px;
  width: 12px;
}
::v-deep .dropdown-toggle:hover::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0em solid;
  border-right: 0em solid transparent;
  border-bottom: 0;
  border-left: 0em solid transparent;
  background-image: url(https://assets.asiantour.com/asian-tour/2024/04/Blue_NavArrow.png);
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: top;
  height: 16px;
  position: relative;
  left: 12px;
  top: 12px;
  width: 12px;
}
.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
  color: #A8B7C9;
}
.mobMenuFooter {
  color: #A8B7C9;
}
.myaccountText {
  padding-left: 15px;
}
.overlay-content > li > a {
  color: #fff!important;
  font-weight: 100;
}
.overlay-content > li.topNavLink > a {
  font-weight: 500;
}
.overlay-contentDrop > li > a {
  color: #fff!important;
  font-weight: 300;
  padding-left: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative!important;
  top: 5px;
}
.overlay-contentDrop > li.topNavLink > a {
  font-weight: 500;
}
hr.hrline {
  border-color: #F2F8FF14;
  margin-bottom: -0.5px;
}
::v-deep .openNav {
  font-size: 40px;
  cursor: pointer;
  color: #fff;
  float: right;
  /* margin-right: 20px; */
  /* margin-top: 5px; */
  position: relative;
  right: 30px;
  top: 16px;
}
.menuMobile {
  height: 100px;
}
.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  background-color: rgb(0,0,0);
  background-color: #042F62;
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 10%;
  width: 100%;
  text-align: left;
  margin-top: 0px;
  padding-left: 30px;
  padding-right: 30px;
  color: #fff;
}
.overlay-contentDrop {
  position: relative;
  top: 10%;
  width: 100%;
  text-align: left;
  margin-top: 0px;
  /* padding-left: 30px;
  padding-right: 30px; */
  color: #fff;
}
.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 16px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.overlay a:hover, .overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: -5px;
  left: 22px;
  font-size: 50px;
  color: #5897FD!important;
}
::v-deep .MoreSection > ul {
  border-radius: 0;
  margin-top: 2.1em;
  margin-left: -8em;
}
::v-deep .MoreSection > ul > li > a {
  text-align: center;
  color: #042F62!important;
  font-size: 14px!important;
}
::v-deep .MoreSection > ul > li > a:hover {
  text-align: center;
  color: #5897E6!important;
  font-size: 14px!important;
  background: transparent;
}
::v-deep .MoreSectionTwo > ul {
  border-radius: 0;
  margin-top: 2.1em;
  margin-left: -2em;
}
::v-deep .MoreSectionTwo > ul > li > a {
  text-align: center;
  color: #042F62!important;
  font-size: 14px!important;
}
::v-deep .MoreSectionTwo > ul > li > a:hover {
  text-align: center;
  color: #5897E6!important;
  font-size: 14px!important;
  background: transparent;
}
::v-deep .navbar-light .navbar-nav .nav-link:hover {
  color: #5897E6!important;
  display: block;
  padding: 0.2rem 0.78rem;
}
.secondNav {
  padding-bottom: 20px;
}
.White_logo {
  width: 124px;
  height: 49px
}
.menuPip {
  top: 0px!important;
}
.inlinePip {
  background: #F2F8FF14;
  height: 30px;
  width: 2px;
  position: relative;
  top: -3px;
}
::v-deep .bg-info {
  background-color: transparent!important;
}
::v-deep .topNavLink:hover > a {
  color: #41a2f6!important;
}
svg.icon.svg-inline--fa.fa-user.fa-w-14 {
  color: #41a2f6;
}
.topNavLink {
  font-weight: 500;
  padding-right: 8px !important;
}
.topNavLink > a {
  color: #fff!important;
}
.navbar-expand-lg {
  flex-flow: row nowrap;
  justify-content: space-between;
}
::v-deep ul.navbar-nav {
  /* margin-top: 25px; */
  margin-top: 8px;
}
::v-deep .navbar-light .navbar-nav .nav-link {
  color: #fff!important;
  display: block;
  padding: 0.2rem 0.78rem;
}
.btn-secondary {
  color: #000!important;
  background-color: transparent;
  border-color: transparent;
  font-size: 14px;
  /* margin-top: 14px!important; */
}
.btn-secondary::after  {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
button.btn.m-1.OOMBTN.btn-secondary.collapsed {
  background: transparent;
  color: black;
  border: 0px;
  font-size: 14px;
  margin-top: 0px!important;
}

button.btn.m-1.OOMBTN.btn-secondary.collapsed::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.OOMBlockMob > a {
  padding-bottom: 10px;
  font-size: 14px;
}
.OOMBlockMob {
  display: inline-grid;
  /* margin-top: 15px; */
}
.menucol {
  margin-top: -10px;
  display: block!important;
  background: #f4f4f4;
}
a.ATOmobMenu {
  padding-bottom: 10px;
  display: block;
}
.collapsible {
  background-color: transparent;
  color: rgb(0, 0, 0);
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 14px;
  text-align: center;
}

/* .active, .collapsible:hover {
  background-color: #555;
} */

.content {
  padding: 0 18px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  display: inline-grid;
  font-size: 14px;
}
::v-deep .dropdown-item {
  font-size: 14px;
}
.oomDropdownLink > a {
  padding: 0px;
}
.oomDropdown {
  /* margin-top: -1.5em; */
  margin-top: 0em;
  display: block;
}
@media only screen and (max-width: 990px) {
  .hideMob {
    display: none;
  }
  .inlinePip {
    display: none;
  }
  .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0);
  }
  .White_logo {
    width: 124px;
    height: 49px;
    position: relative;
    top: 25px;
  }
  .m-1 {
    margin: 0rem !important;
  }
  button.navbar-toggler.ml-auto.not-collapsed {
    position: absolute;
    right: 0;
    top: -2em;
  }
  /* ul.navbar-nav {
    margin-top: 25px;
    width: 100%;
  } */
  .navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    justify-content: space-between;
    padding: 0.5rem 1rem;
  }
  .adtMobile {
    /* display: none; */
    text-align: center;
    margin: auto;
    display: block;
    position: absolute!important;
    left: 0;
    right: 0;
    margin-top: 15px;
  }
  .adtDesk{
    display: block!important;
  }
}
.adtDesk{
  display: none;
}
button.navbar-toggler.ml-auto.collapsed {
  position: absolute;
  right: 0px;
  /* top: 25px; */
  top: -2em;
}
.menu {
  background-color: #042F62;
  background-image: linear-gradient(to right, #042F62 , #0A3F7F);
}
.logoImg {
  width: 100%;
  position: relative;
  top: 12px;
}
.navbar-brand {
  width: 25%;
}
.nav-link {
  color: black;
  font-size: 14px;
}
::v-deep .nav-link>span{
  font-size: 14px;
}
::v-deep a {
  color: #fff;
}
#nav-collapse {
  font-size: 1rem;
}
.navbar {
  padding-bottom: 1%;
  padding-top: 1%;
  padding-left: 0;
  padding-right: 0;
}
.developGreen {
  padding: 0 4%;
}
.nav-item{
  /* padding-left: 3px;
  padding-right: 15px; */
    padding-left: 3px;
    padding-right: 40px;
}
.router-link-exact-active{
  color: #0b3f7e!important;;
}
@media only screen and (max-width: 1024px) {
  ::v-deep .nav-link {
    padding: 0.5rem 0.5rem;
  }
}
@media only screen and (max-width: 425px) {
  .logoImg {
    width: 180%;
  }
}
@media only screen and (max-width: 500px) {
  .overlay-content > li > a {
    color: #fff!important;
    font-weight: 300;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .overlay a {
    padding: 8px;
    text-decoration: none;
    font-size: 18px;
    color: #818181;
    display: block;
    transition: 0.3s;
  }
  .nav-link > span {
    font-size: 18px;
    font-weight: 300;
  }
  hr.hrline {
    border-color: #F2F8FF14;
    margin-bottom: 10.5px;
    /* margin-top: 23px; */
  }
  .overlay-content {
    position: relative;
    top: 12%;
    width: 100%;
    text-align: left;
    margin-top: 0px;
    padding-left: 24px;
    padding-right: 24px;
    color: #fff;
  }
  .overlay-contentDrop {
    position: relative;
    top: 12%;
    width: 100%;
    text-align: left;
    margin-top: 0px;
    /* padding-left: 24px;
    padding-right: 24px; */
    color: #fff;
  }
  .row.sociallinks.AlignPadLeft {
    padding-bottom: 30px;
  }
}
@media only screen and (max-width: 768px) {
  #nav-collapse {
    font-size: 1rem;
    padding-top: 25px;
  }
  nav#topNav {
    padding-bottom: 0%;
  }
}
@media only screen and (max-width: 990px) {
  .logoImg {
    width: 100px;
  }
  .nav-item{
    line-height: 1!important;
  }
}
@media only screen and (max-width: 1200px) {
  .nav-item{
    padding-left: 0;
    padding-right: 0;
  }
}

</style>
